<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">{{ title }}</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="病害名称" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.BHMC"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="监测位置" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.JCWZMC"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="评估" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.PG_DESC"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="时间" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.CJSJ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.BZ"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item label="现场照片" class="from-item-block">
                    <div class="wrapImg scrollbar">
                        <el-image
                            @click="previewFile(item)"
                            v-for="(item, index) in formdata.PIC"
                            :key="index"
                            :src="common.getThumbnailImg(item.path)"
                            fit="cover"
                            class="imgItem pointer"
                        >
                            <div slot="error" style="width: 100%; height: 100%">
                                <SnNoData typeNum="1" width="120px"></SnNoData>
                            </div>
                        </el-image>
                    </div>
                    <!-- <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        :maxSize="500"
                        basicType="picture"
                    ></basic-upload> -->
                </el-form-item>
            </el-form>
        </div>
        <ViewerAlert :imgList="imgList" ref="myImgList"></ViewerAlert>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看
export default {
    components: {
        ViewerAlert,
    },
    name: "",
    mixins: [infoMixin],
    data() {
        return {
            imgList: [],
            title: "",
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "",
                dataDic: {
                    BHMC: "",
                    JCWZMC: "",
                    PG_DESC: "",
                    CJSJ: "",
                    BZ: "",
                },
                PIC: [],
            },
        };
    },
    created() {
        this.title = this.$route.meta.title;
        this.formdata.itemCode = this.$route.meta.itemId;
    },
    mounted() {},
    methods: {
        ...mapActions([]),
        //查看上传附件
        previewFile(item) {
            let ary = [];
            let obj = {
                alt: item.fjmc,
                src: item.path,
                thumbnail: this.common.getThumbnailImg(item.path),
            };
            ary.push(obj);
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer(0);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.wrapImg {
    width: 100%;
    height: 488px;
    .imgItem {
        width: 201px;
        height: 200px;
        margin-right: 10px;
    }
}
</style>
